
<template>
  <div
    id="login"
    style="min-height: calc(100vh - 20px)"
    class="login-page"
    tag="section"
  >
    <b-row>
      <div class="login-box" no-body>
        <div class="login-wrapper">
          <img src="@/assets/images/venue_booking/logo.svg" id="logo_image" />
          <b-form id="login-input-group-1" @submit.prevent="handleSubmit">
            <b-form-group>
              <b-form-input
                id="txt-email"
                type="email"
                placeholder="Enter Email"
                class="mb-3"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
              <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.form.email.$error"
              >
                <div v-if="!$v.form.email.required">
                  Please enter your email
                </div>
                <div v-if="!$v.form.email.email">
                  Please enter valid email Ex. abc@gmail.com
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input
                id="txt-pwd"
                type="password"
                placeholder="Enter Password"
                class="mb-3"
                v-model="$v.form.pwd.$model"
                :state="validateState('pwd')"
              ></b-form-input>
             <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.form.pwd.$error"
              >
                <div v-if="!$v.form.pwd.required">
                  Please enter your password
                </div>
                <div v-if="!$v.form.pwd.minLength">
                  Please enter minimum 8 characters password
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-button type="submit" variant="primary" block class="mt-2">
                <i class="fas fa-sign-in-alt">&nbsp;SIGN IN</i>
              </b-button>
            </b-form-group>
            <!-- <div class="mt-2">
              <b-link to="#" class="link">
                <u>Forgot your password?</u>
              </b-link>
            </div>

            <div class="mt-3">
              <label>Not Registered?</label> &nbsp;
              <b-link to="/register" class="link">
                <u>Create an Account</u>
              </b-link>
            </div> -->
          </b-form>
        </div>
      </div>
    </b-row>
  </div>
</template>
    

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "BoxedLogin",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: "",
      pwd: "",
    },
    checkbox: false,
  }),
  computed: {},
  validations: {
    form: {
      email: {
        required,
        email,
      },
      pwd: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    validateState(email) {
      const { $dirty, $error } = this.$v.form[email];
      return $dirty ? !$error : null;
    },
    handleSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        axios
          .post("/api/login", {
            email: this.form.email,
            password: this.form.pwd,
          })
          .then((resp) => {
            if (resp.data.status) {
              localStorage.setItem("authenticated", true);
              localStorage.setItem(
                "loggedUserData",
                JSON.stringify(resp.data.user)
              );
              localStorage.setItem("token", resp.data.authorisation.token);

              this.$router.push("/dashboard", () => {
                this.$root.$refs.app.showToast(
                  "success",
                  resp.data.message
                );
              });
            } else {
              this.$root.$refs.app.showToast(
                "danger",
                resp.data.message
              );
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.login-page {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#logo_image {
   width: 180px;
  /* margin-left: 90px; */
  /* height: 60px; */
  padding-bottom: 15px;
}
.login-wrapper {
  text-align: center;
}
.login-box {
  background: #fff;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  margin: 8% auto;
}
@media screen and (max-width: 475px) {
  .login-box {
    max-width: 100%;
    margin: 0 30px;
  }
}
</style>